import * as _stringWidth2 from "string-width";

var _stringWidth = "default" in _stringWidth2 ? _stringWidth2.default : _stringWidth2;

var exports = {};
var stringWidth = _stringWidth;
exports.center = alignCenter;
exports.left = alignLeft;
exports.right = alignRight; // lodash's way of generating pad characters.

function createPadding(width) {
  var result = "";
  var string = " ";
  var n = width;

  do {
    if (n % 2) {
      result += string;
    }

    n = Math.floor(n / 2);
    string += string;
  } while (n);

  return result;
}

function alignLeft(str, width) {
  var trimmed = str.trimRight();
  if (trimmed.length === 0 && str.length >= width) return str;
  var padding = "";
  var strWidth = stringWidth(trimmed);

  if (strWidth < width) {
    padding = createPadding(width - strWidth);
  }

  return trimmed + padding;
}

function alignRight(str, width) {
  var trimmed = str.trimLeft();
  if (trimmed.length === 0 && str.length >= width) return str;
  var padding = "";
  var strWidth = stringWidth(trimmed);

  if (strWidth < width) {
    padding = createPadding(width - strWidth);
  }

  return padding + trimmed;
}

function alignCenter(str, width) {
  var trimmed = str.trim();
  if (trimmed.length === 0 && str.length >= width) return str;
  var padLeft = "";
  var padRight = "";
  var strWidth = stringWidth(trimmed);

  if (strWidth < width) {
    var padLeftBy = parseInt((width - strWidth) / 2, 10);
    padLeft = createPadding(padLeftBy);
    padRight = createPadding(width - (strWidth + padLeftBy));
  }

  return padLeft + trimmed + padRight;
}

export default exports;
export const center = exports.center,
      left = exports.left,
      right = exports.right;